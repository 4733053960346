<template>
  <mf-loading-dialog :loading="$apollo.loading || loading">
    <base-page-layout :title="$route.meta.title" :showHeader="isCreate">
      <v-card>
        <v-card-text>
          <v-row justify="end">
            <v-col cols="auto">
              <v-icon @click="startTour">info</v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col id="first-section" cols="12" class="mb-n3">
              <v-row>
                <v-col v-if="retailer.id_client" cols="3">
                  <label class="card-title">ID Varejista</label>
                  <v-text-field v-model="retailer.id_client" disabled outlined :error-messages="errorMessages.fantasy_name" />
                </v-col>
                <v-col :cols="retailer.id_client ? 3 : 4">
                  <label class="card-title">Nome Fantasia</label>
                  <v-text-field v-model="retailer.fantasy_name" outlined :error-messages="errorMessages.fantasy_name" />
                </v-col>
                <v-col :cols="retailer.id_client ? 3 : 4">
                  <label class="card-title">Razão Social</label>
                  <v-text-field v-model="retailer.corporate_name" outlined :error-messages="errorMessages.corporate_name" />
                </v-col>
                <v-col :cols="retailer.id_client ? 3 : 4">
                  <label class="card-title">CNPJ</label>
                  <v-text-field v-model="retailer.cnpj" v-mask="'##.###.###/####-##'" outlined :error-messages="errorMessages.cnpj" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mb-n3">
              <v-row>
                <v-col id="second-section" cols="4">
                  <label class="card-title">Redes</label>
                  <v-autocomplete
                    v-model="retailer.franchise"
                    label="-- Selecione -- "
                    :items="availableFranchisesItems"
                    item-text="fantasy_name"
                    item-value="_id"
                    outlined
                    append-icon="search"
                    clearable
                    :error-messages="errorMessages.franchise"
                  />
                </v-col>
                <v-col id="third-section">
                  <v-row no-gutters>
                    <v-col class="mr-6">
                      <label class="card-title">ERP PDV</label>
                      <v-autocomplete
                        v-model="retailer.erp_pdv"
                        label="-- Selecione -- "
                        :items="erps"
                        item-text="name"
                        item-value="_id"
                        outlined
                        append-icon="search"
                        clearable
                        :error-messages="errorMessages.erp_pdv"
                      />
                    </v-col>
                    <v-col>
                      <label class="card-title">ERP Retaguarda</label>
                      <v-autocomplete
                        v-model="retailer.erp_backoffice"
                        label="-- Selecione -- "
                        :items="erps"
                        item-text="name"
                        item-value="_id"
                        outlined
                        append-icon="search"
                        clearable
                        :error-messages="errorMessages.erp_backoffice"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col id="fourth-section" cols="12" class="mb-n3">
              <v-row>
                <v-col cols="4">
                  <label class="card-title">Segmento do Varejista</label>
                  <v-autocomplete
                    v-model="retailer.segment"
                    label="-- Selecione -- "
                    :items="segments"
                    item-text="text"
                    item-value="text"
                    outlined
                    append-icon="search"
                    :error-messages="errorMessages.segment"
                    clearable
                  />
                </v-col>
                <v-col cols="4">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-row no-gutters justify="end">
                        <v-col cols="auto">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon small>mdi-help-circle</v-icon>
                              </div>
                            </template>
                            <span>
                              Tamanhos de loja
                              <br />
                              P: 1 a 3 lojas
                              <br />
                              M: 4 a 9 lojas
                              <br />
                              G: 10 a 24 lojas
                              <br />
                              KA : 25+ lojas
                            </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="mt-n5">
                      <label class="card-title">Porte Varejista</label>
                      <v-select
                        v-model="retailer.classification_size"
                        :items="classificationsSize"
                        item-text="text"
                        item-value="value"
                        outlined
                        :error-messages="errorMessages.classification_size"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <label class="card-title">Quantidade Total de Lojas Físicas</label>
                  <mf-number-input v-model="retailer.total_stores" showArrows outlined :min="0" :error-messages="errorMessages.total_stores" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mb-n3">
              <v-row>
                <v-col cols="4">
                  <label class="card-title">Estado</label>
                  <v-autocomplete
                    v-model="retailer.address.federative_unit"
                    label="Estado"
                    :items="estados"
                    item-text="name"
                    item-value="value"
                    :error-messages="errorMessages.federative_unit"
                    outlined
                    single-line
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col id="fifth-section" cols="12">
              <v-row>
                <v-col cols="4">
                  <label class="card-title">ID Exact Sales</label>
                  <mf-number-input v-model="retailer.id_exact_sales" :min="0" outlined :error-messages="errorMessages.id_exact_sales" />
                </v-col>
                <v-col cols="4">
                  <label class="card-title">Origem do Varejista</label>
                  <v-autocomplete
                    v-model="retailer.origin"
                    label="-- Selecione -- "
                    :items="origins"
                    item-text="value"
                    item-value="name"
                    outlined
                    :error-messages="errorMessages.origin"
                  />
                </v-col>
                <v-col cols="4">
                  <label class="card-title">Sub Origem do Varejista</label>
                  <v-select
                    v-model="retailer.sub_origin"
                    label="-- Selecione -- "
                    :items="subOrigins"
                    outlined
                    :disabled="!retailer.origin"
                    :error-messages="errorMessages.sub_origin"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-col id="sixth-section" cols="12">
            <h2>Contatos:</h2>
            <contact-form :contacts.sync="retailer.contacts" :validations="$v.retailer" />
          </v-col>
        </v-card-text>
      </v-card>
      <mf-action-buttons
        class="mt-2"
        :primary-button="{
          text: isCreate ? 'Criar Varejista' : 'Editar Varejista',
          action: isCreate ? create : saveRetailer,
          isVisible: true,
          isDisabled: false,
          isLoading: loadingSaveActive
        }"
        :cancel-button="{
          text: 'Cancelar',
          action: () => $router.push('/retailers'),
          isVisible: true,
          isDisabled: false
        }"
      />
    </base-page-layout>
  </mf-loading-dialog>
</template>

<script>
import _ from 'lodash'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'

import { deepDelete } from '@/helpers/deepDelete'
import { QUERY_GET_ERPS } from '@/modules/accounts/graphql'
import { validCnpj, notOnlySpace } from '@/helpers/validators.js'
import { data as estados } from '@/assets/data/estados.json'
import { QUERY_GET_AVAILABLE_FRANCHISES } from '@/modules/franchises/graphql'
import { MUTATION_CREATE_RETAILER, MUTATION_UPDATE_RETAILER, QUERY_GET_ORIGINS, QUERY_SEARCH_CNPJ } from '@/modules/retailers/graphql'
import { retailSegments } from '@/mixin'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    ContactForm: () => import('@/components/form/ContactForm.vue')
  },
  mixins: [retailSegments],
  data: () => ({
    retailer: {
      address: {},
      contacts: [{}]
    },
    estados: estados,
    origins: [],
    loading: false,
    loadingSaveActive: false,
    retailerId: '',
    foundCnpj: []
  }),
  computed: {
    errorMessages() {
      const fields = [
        'cnpj',
        'total_stores',
        'classification_size',
        'corporate_name',
        'id_exact_sales',
        'segment',
        'fantasy_name',
        'sub_origin',
        'origin',
        'franchise',
        'erp_pdv',
        'erp_backoffice'
      ]
      const errors = fields.reduce((errors, field) => {
        if (this.$v.retailer[field] && this.$v.retailer[field].$error) {
          if (!this.$v.retailer[field].required) errors[field] = 'Campo obrigatório.'
          else if (!this.$v.retailer[field].notOnlySpace) errors[field] = 'Não é permitido salvar apenas espaços.'
        } else {
          errors[field] = ''
        }
        return errors
      }, {})
      if (this.$v.retailer.address.federative_unit.$error) errors['federative_unit'] = 'Estado inválido.'
      if (!errors.cnpj && !this.$v.retailer.cnpj.validCnpj) errors.cnpj = 'CNPJ inválido.'
      return errors
    },
    isCreate() {
      return this.$route.meta.title === 'Criar varejista'
    },
    classificationsSize() {
      return [
        {
          text: 'Key account',
          value: 'KEY_ACCOUNT'
        },
        {
          text: 'P',
          value: 'P'
        },
        {
          text: 'M',
          value: 'M'
        },
        {
          text: 'G',
          value: 'G'
        }
      ]
    },
    subOrigins() {
      if (!this.retailer.origin) return []
      return this.origins?.find(origin => origin.name === this.retailer.origin)?.sub_origins
    },
    subOriginsTypes() {
      return {
        Eventos: 'EVENTOS',
        'Indicação Direta': 'INDIC_DIRETA',
        Landpage: 'LANDPAGE',
        Piloto: 'PILOTO',
        'Recuperação Ambev': 'RECUP_AMBEV',
        'The Hub': 'THE_HUB',
        'Recuperação Smart': 'RECUP_SMART',
        'CR Cartões': 'CR_CARTOES',
        Trial: 'TRIAL',
        'Top 500': 'TOP_500',
        'Top 50': 'TOP_50',
        'Leads Estratégicos': 'LEADS_ESTRAT',
        'Cross-Sell Ecommerce - Tricard': 'CROSS_SELL_ECOMM_TRICARD',
        'Recuperação Tricard': 'RECUP_TRICARD',
        Arius: 'ARIUS',
        Avance: 'AVANCE',
        Avanco: 'AVANCO',
        Ciss: 'CISS',
        'Eventos Parcerias': 'EVENTOS_PARCERIAS',
        GDI: 'GDI',
        EGestore: 'EGESTORA',
        Logus: 'LOGUS',
        Oriontec: 'ORIONTEC',
        'Processo de Nutrição': 'PROCESSO_NUTRI',
        'Programa Indica': 'PROG_INDICA',
        'RP Info': 'RP_INFO',
        SD: 'SD',
        'Mídia Paga': 'MIDIA_PAGA',
        'Redes Sociais': 'REDES_SOCIAIS',
        'Tráfego Pago': 'TRAFEGO_DIRETO',
        Email: 'EMAIL',
        SMS: 'SMS',
        'Busca Orgânica': 'BUSCA_ORGANICA',
        Materiais: 'MATERIAIS',
        '9 Bits': 'NINE_BITS',
        Live: 'LIVE',
        'Evento Presencial': 'EVENTO_PRESENCIAL',
        'Cross-Sell Ecommerce - Pré Vendas': 'CROSS_SELL_ECOMM_PRE_VENDAS',
        Google: 'GOOGLE',
        'Indicação CS': 'INDICACAO_CS',
        'Recuperação Pré Vendas': 'RECUP_PRE_VENDAS',
        'Social Selling': 'SOCIAL_SELLING',
        Mercapromo: 'MERCAPROMO',
        'Prospecção CS': 'PROSPECCAO_CS',
        'Pós-evento Lista': 'POS_EVENTO_LISTA',
        'Pré-evento': 'PRE_EVENTO',
        'Evento Indicação': 'EVENTO_INDICACAO',
        'Evento Prospecção': 'EVENTO_PROSPECCAO',
        'Eventos Marketing': 'EVENTOS_MARKETING'
      }
    },
    erps() {
      return [
        {
          _id: '-',
          name: 'Nenhum ERP'
        },
        ...(_.sortBy(this.adminERPs, ['name']) ? _.sortBy(this.adminERPs, ['name']) : [])
      ]
    },
    availableFranchisesItems() {
      return [
        {
          _id: '-',
          fantasy_name: 'Nenhuma Rede'
        },
        ...(this.availableFranchises ? this.availableFranchises : [])
      ]
    },
    retailerValidation() {
      let result = {
        cnpj: { required, validCnpj },
        corporate_name: { required },
        address: {
          federative_unit: { required, notOnlySpace }
        },
        origin: { required },
        franchise: { required },
        erp_pdv: { required },
        erp_backoffice: { required },
        id_exact_sales: { required },
        fantasy_name: { required },
        segment: { required },
        total_stores: { required },
        classification_size: { required },
        contacts: {
          required,
          $each: {
            owner: { required },
            role: { required },
            email: { required, email },
            phone: { required, notOnlySpace, minLength: minLength(18), maxLength: maxLength(19) }
          }
        }
      }
      if (typeof this.subOrigins === 'object' && this.subOrigins.length !== 0) {
        result['sub_origin'] = { required }
      }
      return result
    }
  },
  apollo: {
    availableFranchises: {
      query: QUERY_GET_AVAILABLE_FRANCHISES,
      fetchPolicy: 'network-only',
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    },
    origins: {
      query: QUERY_GET_ORIGINS,
      fetchPolicy: 'network-only',
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      },
      result({ data }) {
        const originsFiltred = data.origins.filter(item => item.name !== 'CLIENTE_ANTIGO')
        if (this.retailer?.cnpj) {
          this.origins = this.retailer?.origin === 'CLIENTE_ANTIGO' ? data.origins : originsFiltred
        } else {
          this.origins = originsFiltred
        }
      }
    },
    adminERPs: {
      query: QUERY_GET_ERPS,
      fetchPolicy: 'network-only'
    }
  },
  watch: {
    'retailer.cnpj'(value) {
      setTimeout(() => {
        this.validateCnpj(value)
      }, 1000)
    },
    'retailer.origin'() {
      this.retailer.sub_origin = ''
    }
  },
  mounted() {
    if (!this.isCreate) {
      this.retailer = {
        ...this.$attrs.retailer,
        address: this.$attrs.retailer?.address || {},
        erp_backoffice: this.$attrs.retailer?.erp_backoffice || '-',
        erp_pdv: this.$attrs.retailer?.erp_pdv || '-',
        franchise: this.$attrs.retailer?.franchise || '-',
        sub_origin: this.getSubOriginKey(this.$attrs.retailer?.sub_origin)
      }
      this.retailerId = this.$route.params.id
    }
  },
  methods: {
    /**
     * Metodo para iniciar o tour
     */
    startTour() {
      const tour = this.$shepherd({
        useModalOverlay: true
      })
      tour.addStep({
        title: 'Dados Cadastrais',
        text: 'Estes campos são referentes a dados cadastrais do varejista',
        attachTo: { element: '#first-section', on: 'bottom' },
        cancelIcon: { enabled: true },
        buttons: [
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Rede',
        text: 'Este campo indica o pertencimento a uma rede',
        cancelIcon: { enabled: true },
        attachTo: { element: '#second-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'ERP',
        text: 'Campos de identificação dos ERPs frente e retaguarda utilizados pelo varejista',
        cancelIcon: { enabled: true },
        attachTo: { element: '#third-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Info sobre varejista',
        text: 'Campos com as características do varejista',
        cancelIcon: { enabled: true },
        attachTo: { element: '#fourth-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Info sobre Venda',
        text: 'Campos referentes à origem da venda',
        cancelIcon: { enabled: true },
        attachTo: { element: '#fifth-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Info dados para contato',
        text: 'Dados de contato com o varejista',
        cancelIcon: { enabled: true },
        attachTo: { element: '#sixth-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Completar',
            action: tour.complete
          }
        ]
      })
      tour.start()
    },
    getSubOriginValue(subOrigin) {
      return this.subOriginsTypes[subOrigin] || ''
    },
    getSubOriginKey(subOrigin) {
      let subOrigins = {}
      Object.entries(this.subOriginsTypes).forEach(entries => {
        subOrigins[entries[1]] = entries[0]
      })
      return subOrigins[subOrigin] || ''
    },
    validateForm() {
      this.$v.$reset()
      this.$v.$touch()
      if (this.$v.$error) {
        this.$alert({
          alert_message: 'Preencha todos os campos corretamente',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
        this.$nextTick(() => this.$vuetify.goTo(document.querySelector('.error--text'), { offset: 200 }))
        return false
      }
      return true
    },
    formatForm() {
      delete this.retailer.accounts

      let retailer = {
        ...this.retailer,
        cnpj: this.retailer.cnpj?.replace(/[^0-9]/g, ''),
        erp_pdv: typeof this.retailer.erp_pdv !== 'string' ? this.retailer.erp_pdv?._id || '' : this.retailer.erp_pdv,
        erp_backoffice: typeof this.retailer.erp_backoffice !== 'string' ? this.retailer.erp_backoffice?._id || '' : this.retailer.erp_backoffice,
        classification_size: this.retailer.classification_size || '',
        origin: this.retailer.origin || '',
        segment: this.retailer.segment || '',
        sub_origin: this.getSubOriginValue(this.retailer.sub_origin) || '',
        total_stores: this.retailer.total_stores || 0
      }

      retailer = _.omitBy(retailer, v => v === undefined || v == null || v === '')

      Object.keys(retailer).forEach(key => {
        if (retailer[key] === '-') retailer[key] = null
      })

      return retailer
    },
    async saveRetailer() {
      const isValidated = this.validateForm()
      if (!isValidated) return
      const retailer = this.formatForm()

      deepDelete(retailer, ['_id', 'id_client', 'active', 'created_at', '__typename'])

      try {
        this.loadingSaveActive = true
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_RETAILER,
          variables: {
            client: retailer,
            client_id: this.$attrs.retailer._id
          },
          context: {
            uri: this.$microservicesUrls['crm']
          }
        })
        setTimeout(() => {
          this.$alert({
            alert_message: `Varejista editado com sucesso`,
            alert_title: 'Sucesso!',
            alert_color: 'success',
            alert_icon: 'mdi-check-circle'
          })
        }, 300)
        this.$router.push(`/retailers`)
      } catch (error) {
        this.$alert({
          alert_message: 'Falha ao editar varejista',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      } finally {
        this.loadingSaveActive = false
      }
    },
    async create() {
      const isValidated = this.validateForm()
      if (!isValidated) return
      if (this.validateClientCnpj())
        return this.$alert({
          alert_message: 'CNPJ inválido ou já cadastrado em nossa base',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      const retailer = this.formatForm()

      this.loadingSaveActive = true

      try {
        const res = await this.$apollo.mutate({
          mutation: MUTATION_CREATE_RETAILER,
          variables: {
            client: retailer
          },
          context: {
            uri: this.$microservicesUrls['crm']
          }
        })

        this.loading = false

        setTimeout(() => {
          this.$alert({
            alert_message: 'Varejista criado com sucesso',
            alert_title: 'Sucesso!',
            alert_color: 'success',
            alert_icon: 'mdi-check-circle'
          })
        }, 300)

        this.$router.push(`/retailers/${res?.data?.registerClient?._id}/edit/dados-do-varejista`)
      } catch (e) {
        console.log(e.message)
        this.$alert({
          alert_message: `Falha ao criar varejista`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.loadingSaveActive = false
    },
    /**
     * Busca o cnpj pelo valor inserido para validar duplicados
     */
    async validateCnpj(value) {
      const search = value?.replace(/[^0-9]/g, '')

      const res = await this.$apollo.query({
        query: QUERY_SEARCH_CNPJ,
        fetchPolicy: 'no-cache',
        variables: { search },
        context: {
          uri: this.$microservicesUrls['crm']
        }
      })

      const cnpj = res?.data?.searchCnpj
      this.foundCnpj = []
      cnpj?.forEach(item => this.foundCnpj.push(item?.cnpj))
    },
    validateClientCnpj() {
      return this.foundCnpj.length > 0
    }
  },
  validations() {
    return {
      retailer: this.retailerValidation
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none !important;
}
</style>
